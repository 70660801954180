import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7119092a")
const _hoisted_1 = { class: "fluid-container hide-on-mobile" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SectionWithImage, {
      class: "special-container",
      thumbnail: _ctx.thumbnail,
      rtl: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SectionTitle, {
          class: "faq-section-title",
          title: "Looking to get into\r\nworld of eSport betting?"
        }),
        _createVNode(_component_FAQ, { items: _ctx.elements }, null, 8, ["items"])
      ]),
      _: 1
    }, 8, ["thumbnail"])
  ]))
}