import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0e020508")
const _hoisted_1 = { class: "container container-table" }
const _hoisted_2 = {
  key: 0,
  class: ""
}
const _hoisted_3 = { class: "fluid-container section-with-image-on hide-on-mobile" }
const _hoisted_4 = { class: "container container-bonuses" }
const _hoisted_5 = {
  key: 0,
  class: "fluid-container container-faq hide-on-mobile"
}
const _hoisted_6 = { class: "container container-news-grid" }
const _hoisted_7 = { class: "fluid-container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_Bookmakers = _resolveComponent("Bookmakers")!
  const _component_Jumbotron = _resolveComponent("Jumbotron")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!
  const _component_SectionbonusesByPopularity = _resolveComponent("SectionbonusesByPopularity")!
  const _component_SectionFaqHorseBetting = _resolveComponent("SectionFaqHorseBetting")!
  const _component_SectionHomeGridNews = _resolveComponent("SectionHomeGridNews")!
  const _component_VideoPopup = _resolveComponent("VideoPopup")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "position-relative main-header",
            style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/demosimages/home-horse-betting-bg.jpeg') +
            ')',
        })
          }, [
            _createVNode(_component_main_header, {
              class: "content-header",
              title: "ONLINE HORSE BETS",
              description: "Nullam turpis lorem, feugiat in augue ac, aliquam vestibulum augue. Nunc rutrum lacinia vestibulum. Integer urna elit.",
              href: "header.button.href",
              buttonName: "All Games"
            })
          ], 4),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_SectionHeading, {
              class: "section-heading",
              title: "Gambling Tips & Tricks",
              subtitle: "Just Flip a Coin",
              description: "At Modeltheme, we show only the best websites and portfolios builtcompletely with passion, simplicity & creativity!"
            }),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_Bookmakers, {
                    items: _ctx.bookmaker.slice(0, 4)
                  }, null, 8, ["items"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SectionWithImage, {
              class: "special-container",
              thumbnail: {
            url: 'images/demosimages/section-with-image-horse.jpeg',
          }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Jumbotron, {
                  title: "Looking to get into the exciting world of online poker?",
                  subtitle: "Just flip a Coin",
                  description: "Dress with crossover V-neckline with ruffled trims. Features long sleeves with ruffled trims, elastic cuffs, a cinched waist and a ruffled hem lorem ipsum dolor sid amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                  buttonName: "Read More",
                  href: "contact"
                })
              ]),
              _: 1
            }, 8, ["thumbnail"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SectionHeading, {
              class: "section-heading",
              title: "Top Bonuses By Popularity",
              subtitle: "Just Flip a Coin",
              description: "At Modeltheme, we show only the best websites and portfolios builtcompletely with passion, simplicity & creativity!"
            }),
            _createVNode(_component_SectionbonusesByPopularity)
          ]),
          (!_ctx.loadingFaq)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_SectionFaqHorseBetting, {
                  thumbnail: {url:'images/demosimages/section-with-image-horse.jpeg'},
                  faq: _ctx.faq.faqList
                }, null, 8, ["thumbnail", "faq"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_SectionHomeGridNews)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_VideoPopup, { youtubeLink: "//www.youtube.com/embed/Ue5ght7izjc?autoplay=1" })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}