import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardCashBack = _resolveComponent("CardCashBack")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    type: "flex",
    gutter: [24, 24],
    justify: "space-between"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        lg: 6,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CardCashBack, {
            bonus: "100",
            category: "cashback",
            popularity: 4,
            rewards: "100% match bonus based on first deposit of £/$/€20+. Additional bonuses.",
            href: "https://modeltheme.com/go/coinflip/"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        lg: 6,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CardCashBack, {
            bonus: "to200",
            category: "cashback",
            popularity: 3,
            rewards: "100% match bonus based on first deposit of £/$/€20+. Additional bonuses.",
            href: "https://modeltheme.com/go/coinflip/"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        lg: 6,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CardCashBack, {
            bonus: "100FS",
            category: "cashback",
            popularity: 2,
            rewards: "100% match bonus based on first deposit of £/$/€20+. Additional bonuses.",
            href: "https://modeltheme.com/go/coinflip/"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        lg: 6,
        xs: 24
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CardCashBack, {
            bonus: "code200",
            category: "cashback",
            popularity: 1,
            rewards: "100% match bonus based on first deposit of £/$/€20+. Additional bonuses.",
            href: "https://modeltheme.com/go/coinflip/"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}