
import { defineComponent } from "vue";
import CardCashBack from "../CardCashBack/CardCashBack.vue";

export default defineComponent({
  name: "Home",
  components: {
    CardCashBack,
  },
});
