
import {defineComponent,PropType} from "vue";


import SectionWithImage from "@/components/SectionWithImage/SectionWithImage.vue";
import FAQ from "@/components/FAQ/FAQ.vue";
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue"
import {Thumbnail} from "@/types"
export default defineComponent({
        name: "Home",
        components: {

            SectionWithImage,
            FAQ,
            SectionTitle
        },
        props:{
           thumbnail: {
            type: Object as PropType<Thumbnail>,
            required: true,
            },
        },
        data(){
            return{
                elements:[
                    {
                    question: "Which Plan is good for me ?",
                    answer:
                    "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
                    },
                    {
                    question: "Do I have to commit to a program",
                    answer:
                    "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
                    },
                    {
                    question: "What Payment Methods are Available?",
                    answer:
                    "Trends, vision dominates a lot of our subconscious interpretation of the world around us. On top it, pleasing images create better user experience.    ",
                    }
                ]
            }
        }
 });
