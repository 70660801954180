
import { defineComponent, PropType } from "vue";

import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import SectionHeading from "@/components/SectionHeading/SectionHeading.vue";
import Jumbotron from "@/components/Jumbotron/Jumbotron.vue";
import SectionWithImage from "@/components/SectionWithImage/SectionWithImage.vue";
import SectionHomeGridNews from "../components/SectionHomeGridNews/SectionHomeGridNews.vue";
import SectionbonusesByPopularity from "@/components/SectionbonusesByPopularity/SectionbonusesByPopularity.vue";
import VideoPopup from "@/components/VideoPopup/VideoPopup.vue";
import SectionFaqHorseBetting from "@/components/SectionFAQHorseBetting/SectionFaqHorseBetting.vue";
import fetchBookmaker from "../misc/composition/fetchBookmakersTable";

import { FAQitem } from "@/types";
import Bookmakers from "@/components/Bookmakers/Bookmakers.vue";
export default defineComponent({
  name: "Home",
  components: {
    MainHeader,
    SectionHeading,
    Jumbotron,
    SectionWithImage,
    SectionbonusesByPopularity,
    SectionHomeGridNews,
    VideoPopup,
    Bookmakers,
    SectionFaqHorseBetting,
  },
  props: {
    items: {
      type: Object as PropType<FAQitem[]>,
      required: true,
    },
  },
  setup() {
    document.title = "Horse Betting - Coinflip";
    const { bookmaker, loading } = fetchBookmaker();
    const { faq, loading: loadingFaq } = fetchHomeFAQ();
    return { faq, loadingFaq, bookmaker, loading };
  },
});
